import React from 'react';
import styled from 'styled-components';
import DataList from './DataList';
import Button from './Button';

const StyledDataListSearchBox = styled.div`
  margin-bottom: 0.5rem;
  form {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    input {
      margin: 0;
      margin-top: -0.2rem;
      padding: 0.53rem;
    }
  }
`;

const DataListSearchBox = ({
  onSubmit,
  onChange,
  value,
  items,
  itemNameKey,
  itemKey,
  identifier,
  placeholder,
}) => {
  return (
    <StyledDataListSearchBox>
      <form onSubmit={onSubmit} className="search-box">
        <DataList
          onChange={onChange}
          value={value}
          items={items}
          itemNameKey={itemNameKey}
          itemKey={itemKey}
          identifier={identifier}
          placeholder={placeholder}
          emitAllChanges
        />
        <Button small type="submit" disabled={value.length < 3}>
          Sök
        </Button>
      </form>
    </StyledDataListSearchBox>
  );
};

export default DataListSearchBox;
