import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/Layout';
import NotificationCard from '../components/NotificationCard';
import ReservoirCard from '../components/reservoirCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { request } from '../utils/request';
import DataListSearchBox from '../components/DataListSearchBox';

const StyledHomePage = styled.div`
  .customer-card-header-right-content {
    display: flex;
    gap: 0.5rem;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    a {
      margin-right: 1rem;
    }
  }
`;

const HomePage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customers, setCustomers] = useState([]);
  const [customerNamesAndIds, setCustomerNamesAndIds] = useState([]);
  const [reservoirNamesAndIds, setReservoirNamesAndIds] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [reservoirsSearchString, setReservoirsSearchString] = useState('');
  const [searchReservoirsHelperText, setSearchReservoirsHelperText] = useState(
    'Sök för att visa reservoarer'
  );
  const [searchString, setSearchString] = useState('');
  const [matchingReservoirs, setMatchingReservoirs] = useState([]);
  const [
    totalNumberOfActiveNotifications,
    setTotalNumberOfActiveNotifications,
  ] = useState(0);

  const getOverviewData = async (q) => {
    const { data } = await request({
      state,
      dispatch,
      path: `customers/overviewdata/${state.user._id}/?q=${q || ''}`,
    });

    if (!data) return;
    setCustomers(data.customers);
    parseNotifications(data.notifications);
  };

  const commitCustomerSearch = (e) => {
    e.preventDefault();
    if (searchString.length > 2) getOverviewData(searchString);
  };

  const parseNotifications = (data) => {
    const onlyActiveNotifications = data.filter((n) => !n.archived);
    const filteredMyActive = onlyActiveNotifications.sort((a, b) => {
      const dateA = new Date(a.dateForHandling);
      const dateB = new Date(b.dateForHandling);
      return dateA - dateB;
    });

    setTotalNumberOfActiveNotifications(onlyActiveNotifications.length);
    setNotifications(filteredMyActive.slice(0, 6));
  };

  const searchReservoirs = async (e) => {
    e.preventDefault();
    if (reservoirsSearchString.length < 3) {
      setSearchReservoirsHelperText('Skriv minst 3 tecken för att söka');
      return;
    }

    const { data } = await request({
      state,
      dispatch,
      path: `customers/search-reservoirs/?q=${reservoirsSearchString}`,
    });
    if (!data) return;
    setMatchingReservoirs(data);
  };

  const getDataLists = async () => {
    const { data } = await request({
      state,
      dispatch,
      path: 'customers/datalist-names?includeReservoirs=true',
      skipLoader: true,
    });
    if (!data) return;
    setCustomerNamesAndIds(data.customers);
    setReservoirNamesAndIds(data.reservoirs);
  };

  const getData = async () => {
    const promises = [getOverviewData(), getDataLists()];
    await Promise.all(promises);
  };

  const handleCustomerSearchStringChange = (e) => {
    setSearchString(e.target.value);
    const exactMatch = customerNamesAndIds.find(
      (customer) => customer.name === e.target.value
    );
    if (exactMatch) {
      navigate(`/customers/single/?id=${exactMatch._id}`);
    }
  };

  const handleReservoirSearchStringChange = (e) => {
    setReservoirsSearchString(e.target.value);
    const exactMatch = reservoirNamesAndIds.find(
      (reservoir) => reservoir.name === e.target.value
    );
    console.log('exactMatch', exactMatch);
    if (exactMatch) {
      navigate(
        `/reservoirs/single/?customerId=${exactMatch.customerId}&reservoirId=${exactMatch._id}`
      );
    }
  };

  useEffect(() => {
    if (state.token) getData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token]);

  return (
    <Layout>
      <StyledHomePage>
        <h1 className="page-header">Översikt</h1>
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <Card>
              <div className="customer-card-header">
                <h3>Sök reservoarer</h3>
                <div className="customer-card-header-right-content">
                  <DataListSearchBox
                    onSubmit={searchReservoirs}
                    onChange={handleReservoirSearchStringChange}
                    value={reservoirsSearchString}
                    items={reservoirNamesAndIds}
                    itemNameKey={'name'}
                    itemKey={'_id'}
                    identifier={'search-reservoirs-overview'}
                    placeholder="Sök reservoarer"
                  />
                </div>
              </div>
              {searchReservoirsHelperText ? (
                <p>{searchReservoirsHelperText}</p>
              ) : null}
              {matchingReservoirs.map((reservoir) => (
                <ReservoirCard
                  inner
                  key={reservoir._id}
                  reservoir={reservoir}
                  customer={{ _id: reservoir.customerId }}
                />
              ))}
            </Card>
            <Card>
              <div className="customer-card-header">
                <div>
                  <h3>Kunder</h3>
                  <Link to="/customers">
                    <Button noBg>Visa alla</Button>
                  </Link>
                </div>
                <div className="customer-card-header-right-content">
                  <DataListSearchBox
                    onChange={handleCustomerSearchStringChange}
                    value={searchString}
                    items={customerNamesAndIds}
                    itemNameKey={'name'}
                    itemKey={'_id'}
                    emitAllChanges
                    identifier={'search-customers-overview'}
                    placeholder="Sök kunder"
                    onSubmit={commitCustomerSearch}
                  />
                </div>
              </div>
              {customers.map((customer) => (
                <CustomerCard inner customer={customer} key={customer._id} />
              ))}
            </Card>
          </div>
          <div className="single-customer-right-content">
            <Card>
              <div className="customer-card-header">
                <h3>Notiser</h3>
                <div className="customer-card-header-right-content">
                  <Link to="/notifications">
                    <Button noBg>
                      Visa alla ({totalNumberOfActiveNotifications})
                    </Button>
                  </Link>
                </div>
              </div>
              {notifications.map((notification) => (
                <NotificationCard
                  noDelete
                  key={notification._id}
                  notification={notification}
                />
              ))}
            </Card>
          </div>
        </div>
      </StyledHomePage>
    </Layout>
  );
};

export default HomePage;
