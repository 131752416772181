import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledDataList = styled.div``;

const DataList = ({
  onChange,
  value,
  items,
  identifier,
  itemKey,
  itemNameKey,
  disabled,
  placeholder,
  emitAllChanges,
  'data-test': dataTest,
}) => {
  const [internalValue, setInternalValue] = useState(
    items.find((item) => item[itemKey] === value)?.[itemNameKey] || ''
  );

  const handleChange = (e) => {
    setInternalValue(e.target.value);
    const selection = items.find(
      (item) => item[itemNameKey] === e.target.value
    );
    if (emitAllChanges) {
      onChange(e);
      return;
    }
    if (selection) {
      onChange({ target: { value: selection[itemKey] } });
    }
  };

  useEffect(() => {
    if (emitAllChanges) {
      setInternalValue(value);
      return;
    }
    setInternalValue(
      items.find((item) => item[itemKey] === value)?.[itemNameKey] || ''
    );
    //eslint-disable-next-line
  }, [value, items, emitAllChanges]);
  return (
    <StyledDataList>
      <input
        placeholder={placeholder}
        list={identifier}
        value={internalValue}
        onChange={handleChange}
        data-test={dataTest}
        disabled={disabled}
      />
      <datalist id={identifier}>
        {items.map((item) => (
          <option value={item[itemNameKey]} key={item[itemKey]} />
        ))}
      </datalist>
    </StyledDataList>
  );
};

export default DataList;
